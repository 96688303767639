@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.wallet-adapter-button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    border-radius: 4px;
}

.wallet-adapter-button-trigger {
    background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
    outline-color: white;
}

.wallet-adapter-button:not([disabled]):hover {
    background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
    background: #404144;
    color: #999;
    cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.wallet-adapter-button-end-icon {
    margin-left: 12px;
}

.wallet-adapter-button-start-icon {
    margin-right: 12px;
}

.wallet-adapter-collapse {
    width: 100%;
}

.wallet-adapter-dropdown {
    position: relative;
    display: inline-block;
}

.wallet-adapter-dropdown-list {
    position: absolute;
    z-index: 99;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    padding: 10px;
    top: 100%;
    right: 0;
    margin: 0;
    list-style: none;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.wallet-adapter-dropdown-list-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    color: #fff;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
    align-self: center;
    fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
    transform: rotate(180deg);
    transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
    opacity: 1;
}

.wallet-adapter-modal-button-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 12px;
    cursor: pointer;
    background: #1a1f2e;
    border: none;
    border-radius: 50%;
}

.wallet-adapter-modal-button-close:focus-visible {
    outline-color: white;
}

.wallet-adapter-modal-button-close svg {
    fill: #777;
    transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
    fill: #fff;
}

.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wallet-adapter-modal-container {
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .wallet-adapter-modal-container {
        margin: 1rem;
        min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
    }
}

.wallet-adapter-modal-wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1050;
    max-width: 400px;
    border-radius: 10px;
    background: #10141f;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    flex: 1 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    width: 100%;
}

.wallet-adapter-modal-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    padding: 64px 48px 48px 48px;
    text-align: center;
    color: #fff;
}

@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size: 18px;
    }
}

.wallet-adapter-modal-list {
    margin: 0 0 12px 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
    font-weight: 400;
    border-radius: 0;
    font-size: 18px;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
    width: 28px;
    height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
    margin-left: auto;
    font-size: 14px;
    opacity: .6;
}

.wallet-adapter-modal-list-more {
    cursor: pointer;
    border: none;
    padding: 12px 24px 24px 12px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #fff;
}

.wallet-adapter-modal-list-more svg {
    transition: all 0.1s ease;
    fill: rgba(255, 255, 255, 1);
    margin-left: 0.5rem;
}

.wallet-adapter-modal-list-more-icon-rotate {
    transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 24px 24px;
    box-sizing: border-box;
}

.wallet-adapter-modal-middle-button {
    display: block;
    cursor: pointer;
    margin-top: 48px;
    width: 100%;
    background-color: #512da8;
    padding: 12px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    color: #fff;
}

@font-face {
    font-family: PoppinsRegular;
    src: url('/Poppins-Regular.ttf');
    weight: 400
}

@font-face {
    font-family: ArchivoBlackRegular;
    src: url('/ArchivoBlack-Regular.ttf');
}

img {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

html {
    background: #151515;
}

body.debug-rects > * {
    outline: 1px solid lawngreen;
}

body.debug-rects div {
    outline: 1px solid red;
}

body.debug-rects h1, body.debug-rects h2, body.debug-rects h3, body.debug-rects h4, body.debug-rects h5, body.debug-rects h6 {
    outline: 1px solid purple;
}

body.debug-rects span, body.debug-rects p {
    outline: 1px solid lemonchiffon;
}

body.debug-rects img, body.debug-rects svg {
    outline: 1px solid cyan;
}

body {
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    text-rendering: geometricPrecision;
    background: #151515;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
}

#__next {
    width: 100%;
    background-color: #151515;
}

#__next.homepage {
   background: linear-gradient(180deg, #1C1F42 0%, #1C1F42 40%, #686eb4 60%, #686eb4 100%);
}

@media (max-width: 900.95px) {
    #__next.homepage {
        background: linear-gradient(180deg, #1C1F42 0%, #1C1F42 60%, #686eb4 80%, #686eb4 100%);
    }
}

.special.wallet-adapter-button {
    background: #37383a;
    height: 36px;
    line-height: 1;
    font-size: 13px;
    color: #e5ebed;
    border: 1px solid #4e4e4e;
    transition: all .2s linear
}

.special2.wallet-adapter-button {
    background: #4876d4;
    height: 36px !important;
    line-height: 1;
    font-size: 13px;
    color: white;
    border: 1px solid #6c8bc8;
    transition: all .2s linear
}

.special2.wallet-adapter-button:hover {
    background: #678dd8 !important;
}

.special.wallet-adapter-button:hover {
    background: #484a4c !important;
}

.wallet-adapter-modal-wrapper {
    background: #4a99b8 !important;
}

.wallet-adapter-modal-list .wallet-adapter-button:hover {
    background: #377087 !important
}

.wallet-adapter-modal-button-close {
    background: #ccf1ff !important;
}

.wallet-adapter-modal {
    z-index: 9999 !important;
}

.wallet-adapter-dropdown-list {
    background: #292c2e !important;
}

.MuiListItem-root:hover {
    background: #A1C5D2 !important;
}

.MuiListItem-root.Mui-selected {
    background: #A1C5D2 !important;
}

.MuiListItem-root:hover .MuiListItemIcon-root {
    color: #222e47 !important;
}

.MuiListItem-root:hover .MuiListItemText-root {
    color: #222e47 !important;
}

.MuiListItem-root.Mui-selected .MuiListItemIcon-root {
    color: #222e47 !important;
}

.MuiListItem-root.Mui-selected .MuiListItemText-root {
    color: #222e47 !important;
}

.react-player-candy-machine {
    display: inline-block
}

.react-player-candy-machine video {
    border-radius: 10px;
    border: 3px solid #1c263d;
}

.link {
    color: #5ebbff
}

.top-bar-right-menu-text {
    color: white;
    font-weight: 700;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    transition: color 0.5s ease;
    cursor: pointer;
    text-transform: uppercase;
}

.top-bar-right-menu-text:hover {
    color: #fddc89
}

#tsparticles {
    height: 100%;
}

.md-table table,
th,
td {
    border: 1px solid black;
    padding: 3px
}

.wp-container p {
    margin-top: 5px;
}

.wp-container h3 {
    margin-bottom: 0px;
}

.wp-container h2 {
    margin-bottom: 0px;
}

.mc-submit-btn {
    font-weight: 700;
    background: #fffc74 !important;
}

.mc-submit-btn:hover {
    background: #fffeb1 !important;
}

.mc-submit-modal-btn {
    background: #5865f2 !important;
    color: white;
    font-weight: 700;
}

.mc-submit-modal-btn.Mui-disabled {
    background: grey !important;
}

.mc-form {
}

.mc-form .MuiInput-root::after {
    border-bottom: 2px solid #555555;
}

.mc-form .MuiInputLabel-root.Mui-focused {
    color: #555555;
}

/* Used just for terms and conditions markdown */
.terms-and-conditions-markdown ul {
    list-style-type: none;
}

.website-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    /*box-shadow: 0px 0px 10px 5px #000000;*/
}

.rotate-center {
    animation: rotate-center 1s ease-in-out both infinite;
}

@keyframes rotate-center {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.shadow-drop-2-center {
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes shadow-drop-2-center {
    0% {
        transform: translateZ(0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        transform: translateZ(50px);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}

.card-shadow {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.3);
}

.text-shadow {
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.pulse {
    animation: pulse 2s linear both infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* Tweaking mui select */
.MuiList-root>.Mui-selected {
    background-color: #e1e1e1!important
}
@font-face {
    font-family: 'Cinzel';
    src: local(''), url(/_next/static/media/Cinzel-ExtraBold.3f6b2a12.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cinzel';
    src: local(''), url(/_next/static/media/Cinzel-Bold.043ad8b7.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cinzel';
    src: local(''), url(/_next/static/media/Cinzel-Medium.80607c6e.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cinzel';
    src: local(''), url(/_next/static/media/Cinzel-SemiBold.20759f52.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cinzel';
    src: local(''), url(/_next/static/media/Cinzel-Regular.cdaa1cd8.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-BoldItalic.f76e3202.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-SemiBold.8958787b.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-Italic.3c6541b1.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-BlackItalic.fc1bc279.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-Black.58cbefcd.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-Light.9e972909.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-MediumItalic.745e1167.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-Regular.2cfcdbb0.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-ExtraBoldItalic.d80f2681.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-ExtraBold.e1a1c748.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-LightItalic.ca4b6dbd.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-Medium.6a1dcc93.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-SemiBoldItalic.fb8883b2.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: local(''), url(/_next/static/media/Figtree-Bold.26a6fb18.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

.slide-in-right {
    animation: slide-in-right 2s ease-out both;
    opacity: 0;
}

.slide-in-left {
    animation: slide-in-left 2s ease-out both;
    opacity: 0;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(32px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-32px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
    }
}

.scale-in-center {
    animation: scale-in-center 2s cubic-bezier(0.950, 0.050, 0.795, 0.035) both
}

@keyframes scale-in-center {
    0% {
        transform: scale(0.8);
        opacity: 0
    }
    80% {
        transform: scale(0.8);
        opacity: 0
    }
    100% {
        transform: scale(1);
        opacity: 1
    }
}

.fade-in {
    animation: fade-in 1.2s cubic-bezier(.39, .575, .565, 1.000) both;
}

.fade-out {
    animation: fade-out 1.2s cubic-bezier(.39, .575, .565, 1.000) both;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.scale-in-ver-center {
    animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes scale-in-ver-center {
    0% {
        transform: scaleY(0);
        opacity: 1;
    }
    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}


@font-face {
    font-family: PoppinsRegular;
    src: url('/Poppins-Regular.ttf');
    weight: 400
}

@font-face {
    font-family: ArchivoBlackRegular;
    src: url('/ArchivoBlack-Regular.ttf');
}

img {
    pointer-events: none;
    user-select: none;
}

html {
    background: #151515;
}

body.debug-rects > * {
    outline: 1px solid lawngreen;
}

body.debug-rects div {
    outline: 1px solid red;
}

body.debug-rects h1, body.debug-rects h2, body.debug-rects h3, body.debug-rects h4, body.debug-rects h5, body.debug-rects h6 {
    outline: 1px solid purple;
}

body.debug-rects span, body.debug-rects p {
    outline: 1px solid lemonchiffon;
}

body.debug-rects img, body.debug-rects svg {
    outline: 1px solid cyan;
}

body {
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    text-rendering: geometricPrecision;
    background: #151515;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
}

#__next {
    width: 100%;
    background-color: #151515;
}

#__next.homepage {
   background: linear-gradient(180deg, #1C1F42 0%, #1C1F42 40%, #686eb4 60%, #686eb4 100%);
}

@media (max-width: 900.95px) {
    #__next.homepage {
        background: linear-gradient(180deg, #1C1F42 0%, #1C1F42 60%, #686eb4 80%, #686eb4 100%);
    }
}

.special.wallet-adapter-button {
    background: #37383a;
    height: 36px;
    line-height: 1;
    font-size: 13px;
    color: #e5ebed;
    border: 1px solid #4e4e4e;
    transition: all .2s linear
}

.special2.wallet-adapter-button {
    background: #4876d4;
    height: 36px !important;
    line-height: 1;
    font-size: 13px;
    color: white;
    border: 1px solid #6c8bc8;
    transition: all .2s linear
}

.special2.wallet-adapter-button:hover {
    background: #678dd8 !important;
}

.special.wallet-adapter-button:hover {
    background: #484a4c !important;
}

.wallet-adapter-modal-wrapper {
    background: #4a99b8 !important;
}

.wallet-adapter-modal-list .wallet-adapter-button:hover {
    background: #377087 !important
}

.wallet-adapter-modal-button-close {
    background: #ccf1ff !important;
}

.wallet-adapter-modal {
    z-index: 9999 !important;
}

.wallet-adapter-dropdown-list {
    background: #292c2e !important;
}

.MuiListItem-root:hover {
    background: #A1C5D2 !important;
}

.MuiListItem-root.Mui-selected {
    background: #A1C5D2 !important;
}

.MuiListItem-root:hover .MuiListItemIcon-root {
    color: #222e47 !important;
}

.MuiListItem-root:hover .MuiListItemText-root {
    color: #222e47 !important;
}

.MuiListItem-root.Mui-selected .MuiListItemIcon-root {
    color: #222e47 !important;
}

.MuiListItem-root.Mui-selected .MuiListItemText-root {
    color: #222e47 !important;
}

.react-player-candy-machine {
    display: inline-block
}

.react-player-candy-machine video {
    border-radius: 10px;
    border: 3px solid #1c263d;
}

.link {
    color: #5ebbff
}

.top-bar-right-menu-text {
    color: white;
    font-weight: 700;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    transition: color 0.5s ease;
    cursor: pointer;
    text-transform: uppercase;
}

.top-bar-right-menu-text:hover {
    color: #fddc89
}

#tsparticles {
    height: 100%;
}

.md-table table,
th,
td {
    border: 1px solid black;
    padding: 3px
}

.wp-container p {
    margin-top: 5px;
}

.wp-container h3 {
    margin-bottom: 0px;
}

.wp-container h2 {
    margin-bottom: 0px;
}

.mc-submit-btn {
    font-weight: 700;
    background: #fffc74 !important;
}

.mc-submit-btn:hover {
    background: #fffeb1 !important;
}

.mc-submit-modal-btn {
    background: #5865f2 !important;
    color: white;
    font-weight: 700;
}

.mc-submit-modal-btn.Mui-disabled {
    background: grey !important;
}

.mc-form {
}

.mc-form .MuiInput-root::after {
    border-bottom: 2px solid #555555;
}

.mc-form .MuiInputLabel-root.Mui-focused {
    color: #555555;
}

/* Used just for terms and conditions markdown */
.terms-and-conditions-markdown ul {
    list-style-type: none;
}

.website-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    /*box-shadow: 0px 0px 10px 5px #000000;*/
}

.rotate-center {
    animation: rotate-center 1s ease-in-out both infinite;
}

@keyframes rotate-center {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.shadow-drop-2-center {
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes shadow-drop-2-center {
    0% {
        transform: translateZ(0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        transform: translateZ(50px);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}

.card-shadow {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.3);
}

.text-shadow {
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.pulse {
    animation: pulse 2s linear both infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* Tweaking mui select */
.MuiList-root>.Mui-selected {
    background-color: #e1e1e1!important
}